import React, { Fragment } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/Layout/index";
import MetaTags from "../components/MetaTags/index";
import styles from "./chartPage.module.css";
import emptyCover from "../images/empty-cover.png";
import emptyThumbnail from "../images/empty-example-thumb.png";
import ChartIcon from "../components/ChartIcon";
import { toTitleCase, unwrapHTMLTag, slugify } from "../utils";
import LinkIcon from "../components/anchor-link.svg";
import { ChartsList } from "../components";
import _ from "lodash";
import { FavoriteButton } from "../components/FavoriteButton";


function ChartPage(props) {
  const { data, location, pageContext } = props;
  const url = data.site.siteMetadata.siteUrl;
  const { 
    title,
    functions,
    visualizationTechniques,
    informationTypes,
    axisDirections,
    tools,
    examples,
    synonyms
  } = data.markdownRemark.frontmatter;

  const { variationOf } = data.markdownRemark.fields;
  const aTitle = toTitleCase(title);
  const { coverImage, anatomyImage, exampleImages, variations, parentChart} = data;
  const variationsList = variations.edges.map( ({node:{fields:{slug},excerpt,frontmatter:{title}}}) => ({slug, title, excerpt}) );
  const examplesMap = exampleImages.edges.reduce((map, i) => ({ ...map, [i.node.base]: i.node.childImageSharp }), {});
  const { prev, next } = pageContext;
  const updatedTime = data.markdownRemark.fileInfo.modifiedTime;
  const overview = data.markdownRemark.html.split('<!--more-->')[0];
  const wrapNames = names => names.map(t => `<em>${t}</em>`).join(" or ");
  const otherNames = (synonyms && (synonyms.length > 0)) ? ` (also known as ${wrapNames(synonyms)})` : "";
  const fullOverview = `<p>The <dfn>${title}</dfn>${otherNames} ${unwrapHTMLTag(overview)}</p>`;
  const content = data.markdownRemark.html.split('<!--more-->')[1];
  const githubBase = "https://github.com/graphopedia/content/blob/master/charts";
  const chartSlug = location.pathname;
  const githubRepoLink = variationOf
    ? `${githubBase}/${variationOf}/variations${chartSlug}/index.md?plain=1`
    : `${githubBase}${chartSlug}/index.md?plain=1`;
  return (
    <Layout
      linksSlot={<PrevNext prev={prev && prev.node} next={next && next.node} chartName={chartSlug} isVariation={variationOf ? true : false}/>}
    >
      <MetaTags
        title={aTitle + " - Graphopedia"}
        description={data.markdownRemark.excerpt}
        thumbnail={coverImage && coverImage.publicURL}
        url={url}
        pathname={chartSlug}
      />

      <div className={styles.chartPage}>
        <div className={styles.coverWrapper}>
         <img className={styles.cover} src={coverImage ? coverImage.publicURL : emptyCover} alt=""/>
        </div>
        <h1 className={styles.title}>{aTitle} <FavoriteButton chartId={_.kebabCase(chartSlug)}/></h1>

        <div className={styles.article}>
          <div className={styles.spacer}/>
          {anatomyImage &&
          <figure className={styles.anatomy}>
            <img src={anatomyImage.publicURL} alt={`anatomy of ${aTitle}`}/>
            <figcaption>Anatomy of {toTitleCase(aTitle)}</figcaption>
          </figure>
          }
          <div className={styles.overview} dangerouslySetInnerHTML={{ __html: fullOverview }}/>
          
          { visualizationTechniques && <VizDNATagsGroup name={"visualization technique"} values={visualizationTechniques} data={data} /> }
          { functions && <TagsGroup name={"function"} values={functions} /> }

          {
            variationOf &&
            <div id="chart_parent" className={styles.parentChart}>
              <h2 id="parent"><AnchorLink id="parent"/>Parent Chart</h2>
              <ChartsList
                posts={[{slug: variationOf, title: parentChart.frontmatter.title, excerpt: parentChart.excerpt, variations: variationsList.length}]}
                isExpanded={true}
                isEmbed={true}
              />
            </div>
          }

          <div dangerouslySetInnerHTML={{ __html: content }}>
          </div>
        </div>

        {examples && examplesMap &&
        <div id="chart_examples" className={styles.examples}>
          <h2 id="examples"><AnchorLink id="examples"/>Examples</h2>
          <ul>
            {examples.map((example, i) => (
              <li key={i}>
                {examplesMap[example.image] ?
                  <Link to={chartSlug + "/example/" + example.image.split(".")[0]} title={example.note}>
                    <figure>
                      <Img fixed={examplesMap[example.image].fixed} className={styles.gatsbyImage}/>
                      <figcaption>{example.title}</figcaption>
                    </figure>
                  </Link>
                  :
                  <a href={example.link} title={example.note}>
                    <figure>
                      <img src={emptyThumbnail} alt=""/>
                      <figcaption>{example.title}</figcaption>
                    </figure>
                  </a>
                }
              </li>
            ))}
          </ul>
        </div>
        }

        {variationsList && (variationsList.length > 0) &&
        <div id="chart_variations" className={styles.variations}>
          <h2 id="variations"><AnchorLink id="variations"/>Variations</h2>
          <ChartsList posts={variationsList} isExpanded={true} isEmbed={true}/>
        </div>
        }

        {tools &&
        <div className={styles.tools}>
          <h2 id="tools"><AnchorLink id="tools"/>Tools</h2>
          <ol>
            {tools.map((tool, i) => (
              <li key={i}><a href={tool.link} target="_blank" rel="noopener noreferrer" key={i}>{tool.name}</a></li>
            ))}
          </ol>
        </div>
        }

        <section className={styles.metaInformation}>
          <div className={styles.editThis}><a href={githubRepoLink} target="_blank" rel="noopener noreferrer">Edit this page</a></div>
          {prev && <Link to={`/${prev.node.fields.slug}`} className={styles.prevLinkBt} title={prev.node.frontmatter.title}>&lt;Previous</Link>
          }
          {updatedTime &&<span className={styles.updatedDate}>Updated: {updatedTime}</span>
          }
          {next && <Link to={`/${next.node.fields.slug}`} className={styles.nextLinkBt} title={next.node.frontmatter.title}>Next&gt;</Link>
          }
        </section>

        {/*
                  <ShareButtons title={title} url={url} pathname={location.pathname} />
                */}
      </div>
    </Layout>
  )
}

const TagsGroup = ({name, values}) => {
  return (
    <p id={`tags-${_.kebabCase(name)}`}>
      <strong>{`${_.capitalize(name)}${values?.length > 1 ? 's' : ''}`} : </strong>
      {values.map((tag, i) => (
        <Fragment key={i}>
          <a className={styles.tagEntry}
             href={`/${_.kebabCase(name)}/${slugify(tag)}`}
             key={i}>
            {tag}
          </a>
          {(i + 1 !== values.length) ? ", " : ""}
        </Fragment>
      ))}
    </p>
  )
}

const VizDNATagsGroup = ({name, values, data}) => {
  const allInformationTypes = data?.allInformationTypesYaml?.edges?.map( ({node}) => node );
  const allVisualizationTechniques = data?.allVisualizationTechniquesYaml?.edges?.map( ({node}) => node );

  // map array to name:questionAnswered
  var allInformationTypesMap = Object.fromEntries(
    allInformationTypes?.map(e => [e.name, e.questionAnswered])
  )

  // map array to id:name
  var allVisualizationTechniquesMap = Object.fromEntries(
    allVisualizationTechniques?.map(e => [e.id, e.name])
  )

  return (
    <p id={`tags-${_.kebabCase(name)}`} className={styles.vizDNATags}>
      <strong>{`${_.capitalize(name)}${values?.length > 1 ? 's' : ''}`} : </strong>
      <ul>
      {values.map((tag, i) => {
        const {techniqueId, informationType, axisDirection} = tag;
        const techniqueName = allVisualizationTechniquesMap[techniqueId];
        if (!techniqueName) {
          console.warn("unknowd techniqueId: ", techniqueId);
        }
        const questionAnswered = allInformationTypesMap[informationType];
        if (!questionAnswered) {
          console.warn(`unknowd informationType: '${informationType}'`);
        }
        return (
          <li key={i}>
           { techniqueName &&
            <a className={styles.tagEntry}
              href={`/${_.kebabCase(name)}/${slugify(techniqueName)}`}>
              {techniqueName}
            </a>
           }
            &nbsp;
            { axisDirection && (<span className={styles.axisDirection}>(<span id="axis-icon">↺</span>  {axisDirection}) </span>) }
            to show&nbsp;
            <a className={styles.tagEntry}
              href={`/information-type/${slugify(informationType)}`}>
              {questionAnswered}
            </a>
          </li>
        )})
      }
      </ul>
    </p>
  )
}

export default ChartPage


const PrevNext = (props) => {

  const { prev, next, chartName, isVariation } = props

  return (
    <div className={styles.prevNext}>
      {prev &&
      <Link to={`/${prev.fields.slug}`} className={styles.prevBtn} title={prev.frontmatter.title}><span></span></Link>
      }
      <span className={styles.iconWrapper}>
        <ChartIcon slug={chartName} isVariation={isVariation}/>
      </span>
      {next &&
      <Link to={`/${next.fields.slug}`} className={styles.nextBtn} title={next.frontmatter.title}><span></span></Link>
      }
    </div>
  )
}

const AnchorLink = (props) => {
  return (
    <a href={`#${props.id}`} aria-label="sources permalink" className="anchorLink before">
      <LinkIcon />
    </a>
  )
}

export const query = graphql`
query PostQuery($slug: String!, $imageDir: String!, $examplesDir : String, $parentRef : String) {
  markdownRemark(fields: { slug: { eq: $slug } }) {
    html
    excerpt(format: PLAIN)
    overview:excerpt(format: HTML)
    fields {
      variationOf
    }
    frontmatter {
      title
      synonyms
      functions
      visualizationTechniques{
        techniqueId,
        informationType,
        axisDirection
      }
      informationTypes
      axisDirections
      examples {
        title
        link
        note
        image
      }
      tools {
        name
        link
      }
    }
    fileInfo:parent {
      ... on File {
        modifiedTime(formatString: "MM/DD/YYYY")
      }
    }
  }
  coverImage:file(relativeDirectory: {eq: $imageDir}, name: {eq: "cover"}, extension: {eq:"png"}) {
    publicURL
  }
  anatomyImage:file(relativeDirectory: {eq: $imageDir}, name: {eq: "anatomy"}, extension: {eq:"png"}) {
    publicURL
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
  exampleImages: allFile(filter: {relativeDirectory : { eq: $examplesDir }}) {
    edges {
				node {
          base,
          relativeDirectory
          childImageSharp {
            original {
              width,
              height
              src
            }
            fixed(width: 300, height: 300, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
    }
  }

  variations: allMarkdownRemark(filter:{fields:{variationOf:{eq:$slug}}}, sort: { order: ASC, fields:fields___slug}) {
    edges {
      node {
        fields{
          slug,
          variationOf
        }
        excerpt(pruneLength: 250)
        frontmatter {
          title
        }
      }
    }
  }

  parentChart: markdownRemark(fields: {slug: {eq: $parentRef}}) {
    excerpt(format: PLAIN, pruneLength: 250)
    overview: excerpt(format: HTML)
    fields {
      slug
    }
    frontmatter {
      title
    }
  }

  allVisualizationTechniquesYaml {
    edges {
      node {
        id,
        name
      }
    }
  }

  allInformationTypesYaml {
    edges {
      node {
        id,
        name,
        questionAnswered
      }
    }
  }

}
`
